/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// HELPERS
	var addAnimations = function (animations, trigger, hook, offset, duration, controller) {
		var scene = '';
		var tween = new TimelineMax();
		tween.insertMultiple(animations, 0, 0);

		if (hook && duration) {
			scene = new ScrollMagic.Scene({
				triggerElement: trigger,
				triggerHook: hook,
				offset: offset,
				duration: duration
			})
					.setTween(tween)
					.addTo(controller);
		} else if (duration) {
			scene = new ScrollMagic.Scene({
				triggerElement: trigger,
				offset: offset,
				duration: duration
			})
					.setTween(tween)
					.addTo(controller);
		} else {
			scene = new ScrollMagic.Scene({
				triggerElement: trigger,
				offset: offset
			})
					.setTween(tween)
					.addTo(controller);
		}

		return;
	};

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Hyperdrive = {
		// All pages
		'common': {
			init: function() {
				// Navigation
				var stickyHeaderUpdate = stickyHeader('header');
				stickyHeaderUpdate();
				$(window).on('scroll', stickyHeaderUpdate);
				function stickyHeader(header) {
					var sticky = false,
					$header = $(header);
					return function update() {
						if (window.scrollY > 100) {
							if (!sticky) {
								$header.addClass('sticky');
								sticky = true;
							}
						} else {
							if (sticky) {
								$header.removeClass('sticky');
								sticky = false;
							}
						}
					};
				} // stickyHeader()

				// Mobile Menu
				function toggleMenu() {
					if ($('#mobilemenu').hasClass("open")) {
						$('#mobilemenu').removeClass("open");
						$('#mobilemenu').addClass('opened');
						$('body').css('overflow', 'hidden');
					} else {
						$('#mobilemenu').addClass('open');
						$('#mobilemenu').removeClass('opened');
						$('body').css('overflow', 'auto');
					}
				}

				// Hamburger
				$('.hamburger').on('click', function(event) {
					event.preventDefault();
					$('.bar').toggleClass('animate');
					toggleMenu();
				});
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home_page': {
			init: function() {
				// ::
				// SEARCH FORM ---------------------------::
				// ____
				// Animate in Form
				TweenMax.fromTo(".search-hero .form", 0.6, {
					opacity: "0", y: 60}, {
					opacity: "1", y: 0, delay: 1, ease: Power1.easeOut
				});
				// Animate in H4
				TweenMax.fromTo(".search-hero h4", 0.6, {
					opacity: "0", y: -40}, {
					opacity: "0.8", y: 0, delay: 1.3, ease: Power1.easeOut
				});
				// Animate in H1
				TweenMax.fromTo(".search-hero h1", 0.6, {
					opacity: "0", x: 40}, {
					opacity: "1", x: 0, delay: 1.5, ease: Power1.easeOut
				});
				// Animate in Clear
				TweenMax.fromTo(".search-hero .search-btn ", 0.6, {
					opacity: "0"}, {
					opacity: "1", delay: 1.7, ease: Power1.easeOut
				});

				// Select Dropdown
				$(".form .select").click(function(){
					var button = $(this);
					var menu = button.siblings(".dropdown-menu");
					var isMultiple = $(this).hasClass('multiple-select');

					// Close any open fields first
					if (($(".form .select").hasClass('opened')) && (!(button))) {
						$(".form .select .dropdown-menu").removeClass("show-menu");
						$(".form .select").removeClass("opened");
						console.log("close other menus");
					}

					if (button.hasClass('opened')) {
						button.removeClass("opened");
						menu.removeClass("show-menu");
					} else {
						button.addClass("opened");
						menu.addClass("show-menu");
					}

					// Select Item
					menu.children("li").click(function() {
						if (isMultiple) {
							$(this).toggleClass('selected');
							if (menu.children("li").hasClass('selected')) {
								button.children('.label').html('Multiple Selected');
							} else {
								button.children('.label').html('Any Features');
							}
						} else {
							menu.removeClass("show-menu");
							button.removeClass("opened");
							button.children('.label').html($(this).html());
						}
					});
				});

				// Close all dropdown onclick on another element
				/*$(document).bind('click', function (e) {
					if (!$(e.target).parents().hasClass('form')) {
						$('.select').removeClass('opened');
						$('.select').siblings('.dropdown-menu').removeClass('show-menu');
					}
				});*/

				// Open Additional Fields
				$('.open-trigger').click(function(e){
					e.preventDefault();
					$('.open-fields').css('display', 'none');
					$('.additional-fields').css('display', 'flex');
				});

				// Toggle the Toggle
				$('.toggle').click(function(e){
					e.preventDefault();
					$(this).toggleClass('toggle-on');

					if ($(this).hasClass('toggle-on')) {
						$('.search-form #val-redtag').val(1);
					} else {
						$('.search-form #val-redtag').val(0);
					}
				});

				// Process Search Form
				$('#vehicle-type').on('click', function() {
					$('.search-form #val-vehicle-type').val($('.vehicle-type-label').text());
				});
				$('#vehicle-make').on('click', function() {
					$('.search-form #val-vehicle-make').val($('.vehicle-make-label').text());
				});
				$('#vehicle-model').on('click', function() {
					$('.search-form #val-vehicle-model').val($('.vehicle-model-label').text());
				});
				$('#mileage').on('click', function() {
					$('.search-form #val-mileage').val($('.mileage-label').text());
				});
				$('#vehicle-year').on('click', function() {
					$('.search-form #val-vehicle-year').val($('.vehicle-year-label').text());
				});

				$('#vehicle-features').on('click', function() {
					var features = [];

					$(this).find('.selected').each(function () {
						features.push($(this).html());
					});
					var features_value = features.join('|');
					$('.search-form #val-features').val(features_value);
				});

				// ::
				// HERO PARALLAX ---------------------------::
				// ____
				var parallaxcontrol = new ScrollMagic.Controller();
				var windowHalfX = window.innerHeight / 2;

				addAnimations([
					TweenMax.fromTo(".hero .hero-video", 2, {
						y: "0"}, {
						y: "20%", ease: Linear.easeNone
					}),
					TweenMax.fromTo(".search-container", 2, {
						y: "0"}, {
						y: "-50%", ease: Linear.easeNone
					}),TweenMax.fromTo(".arrow-container", 1, {
						y: "0"}, {
						y: "-50%", ease: Linear.easeNone
					})
				], '.hero', 'onLeave', 0, "180%", parallaxcontrol);

				// ::
				// PAGE SCROLL DOWN ---------------------------::
				// ____
				$('.arrow-down').on('click', function(event) {
					event.preventDefault();
					var target = $(this).attr('href');
					var destination = $(target).offset().top - 90; //offset the nav
					$('body, html').animate({
						scrollTop: destination + 'px'
					}, 1000);
				});

				// ::
				// CALL TO ACTION PARALLAX ---------------------------::
				// ____
				addAnimations([
					TweenMax.fromTo(".call-to-action .background", 2, {
						y: "-10%"}, {
						y: "16%", ease: Linear.easeNone
					})
				], '.call-to-action', '', -windowHalfX, "120%", parallaxcontrol);
			},
			finalize: function() {
			}
		},
		// Sub Page
		'sub_page': {
			init: function() {
				// ::
				// HERO PARALLAX ---------------------------::
				// ____
				var parallaxcontrol = new ScrollMagic.Controller();
				var windowHalfX = window.innerHeight / 2;

				addAnimations([
					TweenMax.fromTo(".subhero .background", 2, {
						y: "0"}, {
						y: "30%", ease: Linear.easeNone
					}),
					TweenMax.fromTo(".subhero h1", 2, {
						y: "0"}, {
						y: "-50%", ease: Linear.easeNone
					})
				], '.subhero', 'onLeave', 0, "100%", parallaxcontrol);
			},
			finalize: function() {
			}
		},
		// Vehicle
		'vehicle_page': {
			init: function() {
				$('.vehicle-image').modaal({
				    type: 'image'
				});
			},
			finalize: function() {
			}
		},
		// Contact
		'contact_page': {
			init: function() {
			},
			finalize: function() {
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Hyperdrive;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
